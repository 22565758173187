import './polyfills'
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import Popper from 'popper.js'
import App from './App'
import * as serviceWorker from './serviceWorker'

import { Provider } from 'react-redux'
import store from './store'
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n";

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

Sentry.init({
    dsn: "https://f162ba401ede4dd3a67f79ff7c137076@o109810.ingest.sentry.io/6160885",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
});

const intlConfig = {
    locale: "da-DK",
    options: {
        style: 'currency',
        currency: "DKK",
        maximumFractionDigits: 2
    }
}

const intl = new Intl.NumberFormat(intlConfig.locale, intlConfig.options);

const rootElement = document.getElementById('root')
ReactDOM.render(
    <Provider store={store}>
        <I18nextProvider i18n={i18n}>
            <App currency={intl}/>
        </I18nextProvider>
    </Provider>, rootElement
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
